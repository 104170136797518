//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Box,
  Link,
  Hidden,
} from "@material-ui/core";
import handShakeIcon from "../../assets/handshake.png";
import callIcon from "../../assets/phone-call.png";
import decisionIcon from "../../assets/directions.png";
import addImg from "../../assets/0937e2df00dec93b5a6feecbdcc621dc27832c38.png";
import moment from "moment";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import TernaryCheck from "../TernaryCheck.web";

export type Props = {
  id: string;
  selectedPackageList: [];
  // Customizable Area Start
  // Customizable Area End
};

export const ExecutionAndNextSteps = ({ classes }) => {
  return (
    <>
      <CustomGrid item xs={12} margin="2rem 0 0 0" padding="0 2rem">
        <CustomTypography
          className={classes.secondaryColorText}
          fontSize="1.7rem"
          fontWeight={700}
        >
          Execution
        </CustomTypography>
      </CustomGrid>

      <CustomGrid item xs={12} margin="0 0 1rem 0" padding="0 2rem">
        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.executionFeatures}>
            <Box className={classes.smallTriangleRight} sx={{ mr: 1.5 }} />
            We will work with you to define & prioritize work items based on
            your goals.
          </Typography>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.executionFeatures}>
            <Box className={classes.smallTriangleRight} sx={{ mr: 1.5 }} />
            We will execute as many work items as are feasible for the
            engagement period & package chosen.
          </Typography>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.executionFeatures}>
            <Box className={classes.smallTriangleRight} sx={{ mr: 1.5 }} />
            As work items are completed, we will update and re-prioritize action
            items to ensure our efforts are always focused on your immediate
            needs.
          </Typography>
        </Grid>
      </CustomGrid>

      <CustomGrid item xs={10} width="100%" margin="2rem 0" id="exeee">
        <CustomTypography
          className={classes.secondaryColorText}
          fontSize="1.7rem"
          fontWeight={700}
          style={{ textAlign: "center" }}
        >
          Next Steps
        </CustomTypography>
      </CustomGrid>

      <CustomGrid
        item
        xs={10}
        width="100%"
        margin="1rem 0"
        container
        justifyContent="center"
      >
        <CustomGrid
          item
          xs={4}
          container
          alignItems="center"
          direction="column"
          className={classes.boxStyle2}
        >
          <img
            src={decisionIcon}
            style={{
              width: "35%",
            }}
          />
          <Typography className={classes.nextStepsPrimaryText}>
            Decision
          </Typography>
          <Typography className={classes.nextStepsSecondaryText}>
            Choose the option that best fits your needs.
          </Typography>
        </CustomGrid>

        <CustomGrid
          item
          xs={4}
          className={classes.boxStyle2}
          container
          alignItems="center"
          direction="column"
        >
          <img
            src={handShakeIcon}
            style={{
              width: "35%",
            }}
          />
          <Typography className={classes.nextStepsPrimaryText}>
            Onboarding
          </Typography>
          <Typography className={classes.nextStepsSecondaryText}>
            eSign your enrollment documents, make your payment, & we will
            designate resources for your work.
          </Typography>
        </CustomGrid>

        <CustomGrid
          item
          xs={4}
          className={classes.boxStyle2}
          container
          alignItems="center"
          direction="column"
        >
          <img
            src={callIcon}
            style={{
              width: "35%",
            }}
          />
          <Typography className={classes.nextStepsPrimaryText}>
            Kickoff Call
          </Typography>
          <Typography className={classes.nextStepsSecondaryText}>
            We can schedule a kickoff call to get started!
          </Typography>
        </CustomGrid>
      </CustomGrid>
    </>
  );
};

export class RoadMapUI extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  getPhoneNumber = () => {
    let number = "";
    if (
      this.props.roadmap?.attributes?.phone_number &&
      this.props.roadmap?.attributes?.web_url
    ) {
      number = ` | ${this.props.roadmap?.attributes?.phone_number}`;
    } else if (this.props.roadmap?.attributes?.phone_number) {
      number = `${this.props.roadmap?.attributes?.phone_number}`;
    }

    if (this.props.roadmap?.attributes?.phone_number) {
      return this.formatPhoneNumber(number);
    } else {
      return null;
    }
  };

  mapOfPackageHeadings = (paymentTerm, index) => {
    const { classes } = this.props;

    const isSelected =
      paymentTerm.id &&
      paymentTerm.id === this.props?.selectedPaymentTerm?.id &&
      paymentTerm?.attributes?.selectedPaymentTerm?.paymentTermKey ==
        this.props?.selectedPaymentTerm?.attributes?.selectedPaymentTerm
          ?.paymentTermKey;

    const packageClass = isSelected
      ? classes.selectedPackageCard
      : classes.packageCard;

    let categories = paymentTerm?.attributes?.features?.data || [];
    return (
      <CustomGrid
        key={index}
        item
        xs={4}
        container
        direction="column"
        className={packageClass}
      >
        <CustomTypography
          fontSize="1.5rem"
          fontWeight={700}
          margin="1rem 0 0.5rem 0"
          className={classes.packageIndex}
        >
          Package - {index + 1}
        </CustomTypography>
        <CustomTypography
          fontSize="1.2rem"
          fontWeight={700}
          margin="0 0 0.25rem 0"
          color="black"
        >
          {paymentTerm?.attributes?.name}
        </CustomTypography>
        <CustomTypography fontSize="1.2rem" margin="0 0 0.5rem 0" color="black">
          {paymentTerm?.attributes?.selectedPaymentTerm?.value}
        </CustomTypography>
        <TernaryCheck
          condition={this.props.isPackageSelectable}
          trueComponent={
            <Button
              className={classes.buttonStyle}
              onClick={() => this.props?.handleSelectPaymentTerm(paymentTerm)}
            >
              Select this Package
            </Button>
          }
          falseComponent={null}
        />
        <TernaryCheck
          condition={paymentTerm?.attributes?.package_detail_link}
          trueComponent={
            <Link
              href={
                paymentTerm?.attributes?.package_detail_link?.indexOf(
                  "https"
                ) === 0
                  ? paymentTerm?.attributes?.package_detail_link
                  : `https://${paymentTerm?.attributes?.package_detail_link}`
              }
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              underline="none"
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                }}
                className={classes.packageIndex}
              >
                More Details
              </Typography>
            </Link>
          }
          falseComponent={null}
        />
        {categories.map((category) => {
          return (
            <Typography
              key={category?.attributes?.feature_name}
              color="textSecondary"
              className={classes.featureText2}
            >
              <Box className={classes.checkMark} />
              {category?.attributes?.feature_name}
            </Typography>
          );
        })}
      </CustomGrid>
    );
  };

  sortPaymentTerms = (a, b) => {
    let fa = parseInt(a?.attributes?.selectedPaymentTerm?.id),
      fb = parseInt(b?.attributes?.selectedPaymentTerm?.id);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };

  renderPackageHeadings = () => {
    let handleArray = this.props.packagePaymentTerms.slice();
    
    handleArray.sort(this.sortPaymentTerms);
    
    return handleArray.map(this.mapOfPackageHeadings);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <CustomGrid item xs={12} padding="0 2rem">
          <Grid container alignItems="center" justifyContent="space-between">
            <TernaryCheck
              condition={this.props.logo}
              trueComponent={
                <Grid item xs={2} className={classes.imageBox}>
                  <img
                    src={this.props.logo}
                    alt="No logo available"
                    className={classes.logoImage}
                  />
                </Grid>
              }
              falseComponent={
                <Grid item xs={2}>
                  <Box className={classes.logoBox}>
                    <img src={addImg} className={classes.defaultlogoImage} />
                    <Typography variant="body2" display="block">
                      Your logo here
                    </Typography>
                  </Box>
                </Grid>
              }
            />
            <Grid item xs={3}>
              <CustomTypography
                color="textSecondary"
                fontSize="1.1rem"
                fontWeight={700}
                align="right"
                padding="0 1rem 0 0"
              >
                {moment(new Date()).format("MM-DD-YYYY")}
              </CustomTypography>
            </Grid>
          </Grid>
        </CustomGrid>
        <Grid
          item
          xs={12}
          container
          className={classes.borderBottom21}
          direction="column"
        >
          <Typography
            align="left"
            className={`${classes.pageTitle} ${classes.primaryColorText}`}
          >
            My Roadmap
          </Typography>
          <CustomTypography
            align="left"
            margin="0 0 0.9rem 0"
            className={`${classes.pageTitle} ${classes.primaryColorText}`}
          >
            <span style={{ color: "black" }}>Prepared for </span>
            {this.props.roadmap?.attributes?.client_information?.data
              ?.attributes?.first_name || "NA"}{" "}
            {this.props.roadmap?.attributes?.client_information?.data
              ?.attributes?.last_name || ""}{" "}
            {this.props.roadmap?.attributes?.client_information?.data
              ?.attributes?.spouse_first_name
              ? "&"
              : null}{" "}
            {this.props.roadmap?.attributes?.client_information?.data
              ?.attributes?.spouse_first_name || ""}{" "}
            {this.props.roadmap?.attributes?.client_information?.data
              ?.attributes?.spouse_last_name || ""}
          </CustomTypography>
        </Grid>

        <CustomGrid item xs={10} width="100%" padding="0 2rem 4rem 2rem">
          <CustomTypography
            color="textPrimary"
            fontSize="1.7rem"
            fontWeight={700}
          >
            Progress you would like to make in the next 12 months:
          </CustomTypography>
          <Box className={classes.prioContainer}>
            <CustomTypography
              color="textSecondary"
              fontSize="1.1rem"
              className={classes.priorityText}
            >
              {this.props.roadmap?.attributes?.priorities?.data[0]?.attributes
                ?.name || "NA"}
            </CustomTypography>
            <Hidden smDown>
              <Box className={classes.triangleRight} sx={{ mr: 2.2 }} />
            </Hidden>
            <CustomTypography
              color="textSecondary"
              fontSize="1.1rem"
              className={classes.priorityText}
            >
              {this.props.roadmap?.attributes?.priorities?.data[1]?.attributes
                ?.name || "NA"}
            </CustomTypography>
            <Hidden smDown>
              <Box className={classes.triangleRight} sx={{ mr: 2.2 }} />
            </Hidden>
            <CustomTypography
              color="textSecondary"
              fontSize="1.1rem"
              className={classes.priorityText}
            >
              {this.props.roadmap?.attributes?.priorities?.data[2]?.attributes
                ?.name || "NA"}
            </CustomTypography>
          </Box>
        </CustomGrid>

        <CustomGrid item xs={10} width="100%" margin="1rem 0 3rem 0">
          <CustomTypography
            align="center"
            className={classes.secondaryColorText}
            fontSize="1.7rem"
            fontWeight={900}
          >
            Your Package Options
          </CustomTypography>
        </CustomGrid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            {this.renderPackageHeadings()}
          </Grid>
        </Grid>
        <div id="ExecutionAndNextStepsconer">
          <ExecutionAndNextSteps classes={classes} />
        </div>

        <CustomGrid
          item
          xs={10}
          width="100%"
          margin="4rem auto"
          padding="0 2rem"
        >
          <CustomTypography
            className={classes.secondaryColorText}
            fontSize="1.7rem"
            fontWeight={700}
          >
            Confidentiality & Disclaimer
          </CustomTypography>
          <Typography className={classes.disclaimerStyle}>
            {this.props.roadmap?.attributes?.confidential_statement ||
              "This has been prepared for the purpose of providing tax, accounting and strategic advisory services. This information should not be shared, has not been audited, and does not imply we're providing investment advice."}
          </Typography>
        </CustomGrid>

        <CustomGrid
          item
          xs={10}
          width="96%"
          margin="1rem 2%"
          style={{
            boxShadow: "none",
          }}
        >
          <CustomGrid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            margin="0 0 0.75rem 0"
          >
            <TernaryCheck
              condition={this.props.logo}
              trueComponent={
                <img
                  src={this.props.logo}
                  alt="No logo available"
                  style={{
                    width: "100px",
                    marginRight: "1rem"
                  }}
                />
              }
              falseComponent={
                <Box className={classes.logoBox}>
                  <img
                    src={addImg}
                    style={{
                      width: "30%",
                      marginRight: "1rem"
                    }}
                  />
                  <Typography variant="body2" display="block">
                    Your logo here
                  </Typography>
                </Box>
              }
            />
            <Grid item xs={6}>
              <TernaryCheck
                condition={this.props.roadmap?.attributes?.web_url}
                trueComponent={
                  <Link
                    href={
                      this.props.roadmap?.attributes?.web_url.indexOf(
                        "https"
                      ) === 0
                        ? this.props.roadmap?.attributes?.web_url
                        : `https://${this.props.roadmap?.attributes?.web_url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                  >
                    <Typography
                      className={`${classes.primaryColorText} ${classes.urlAndPhoneText}`}
                      align="center"
                      display="inline"
                    >
                      {this.props.roadmap?.attributes?.web_url}
                    </Typography>
                    <TernaryCheck
                      condition={
                        this.props.roadmap?.attributes?.web_url &&
                        this.props.roadmap?.attributes?.phone_number
                      }
                      trueComponent={
                        <Typography
                          className={`${classes.primaryColorText} ${classes.urlAndPhoneText} ${classes.middleBar}`}
                          align="center"
                          display="inline"
                        >
                          |
                        </Typography>
                      }
                      falseComponent={null}
                    />
                  </Link>
                }
                falseComponent={null}
              />
              <Typography
                className={`${classes.primaryColorText} ${classes.urlAndPhoneText}`}
                align="center"
                display="inline"
              >
                {this.getPhoneNumber()}
              </Typography>
            </Grid>
          </CustomGrid>
        </CustomGrid>
      </Grid>
    );
  }
}

export const roadmapStyles = (theme) => {
  return {
    prioContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    priorityText: {
      paddingRight: "1rem",
      minWidth: "33%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "1rem",
      },
    },
    iconStyle: {
      width: "13px",
      fill: localStorage.getItem("primaryColor") || theme.palette.primary.main,
    },
    primaryColorText: {
      color: localStorage.getItem("primaryColor") || theme.palette.primary.main,
    },
    secondaryColorText: {
      color:
        localStorage.getItem("secondaryColor") || theme.palette.secondary.main,
    },
    borderBottom21: {
      borderBottom: `1px solid ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
      margin: "0 2rem",
      marginBottom: "3rem",
    },
    logoBox: {
      border: "0.1px solid #6A6B6D",
      borderRadius: "12px",
      padding: "5px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: theme.spacing(10),
      width: theme.spacing(20),
    },
    imageBox: {
      borderRadius: theme.spacing(1.5),
      textAlign: "center",
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    defaultlogoImage: {
      height: "50%",
    },
    logoImage: {
      height: "100%",
    },
    pageTitle: {
      fontSize: "2.2rem",
      fontWeight: "bold",
    },
    urlAndPhoneText: {
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      },
    },
    boxStyle2: {
      flexGrow: "0",
      maxWidth: "20%",
      flexBasis: "20%",
      margin: "0 2%",
      borderRadius: 15,
      backgroundColor:
        localStorage.getItem("primaryColor") || theme.palette.primary.main,
      textAlign: "center",
      padding: "1rem",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "32%",
        flexBasis: "32%",
        margin: "0 auto",
      },
    },
    nextStepsPrimaryText: {
      fontSize: "2rem",
      fontWeight: 900,
      color: "white",
      marginTop: "1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    nextStepsSecondaryText: {
      fontSize: "1rem",
      fontWeight: "normal",
      color: "white",
      margin: "0.8rem 0",
    },
    packageCard: {
      flexGrow: "0",
      maxWidth: "30%",
      flexBasis: "30%",
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      borderRadius: "2rem",
      margin: "0 1.5%",
      textAlign: "center",
      padding: "1rem",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "60%",
        flexBasis: "60%",
        margin: "1rem auto",
      },
    },
    selectedPackageCard: {
      flexGrow: "0",
      maxWidth: "30%",
      flexBasis: "30%",
      boxShadow: `0px 0px 18px 0px ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
      borderRadius: "2rem",
      margin: "0 1.5%",
      textAlign: "center",
      padding: "1rem",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "60%",
        flexBasis: "60%",
        margin: "1rem auto",
      },
    },
    packageIndex: {
      color: localStorage.getItem("primaryColor") || theme.palette.primary.main,
    },
    featureText2: {
      marginBottom: "0.75rem",
      fontSize: "1.1rem",
      position: "relative",
      padding: "0 1.25rem",
      display: "flex",
      alignItems: "flex-start",
      textAlign: "left",
    },
    checkMark: {
      display: "inline-block",
      transform: "rotate(45deg)",
      width: "6px",
      height: "12px",
      borderBottom: `2px solid ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
      borderRight: `2px solid ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
      marginRight: "1rem",
      marginTop: "0.35rem",
    },
    disclaimerStyle: {
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      marginTop: "10px",
    },
    executionFeatures: {
      display: "flex",
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      lineHeight: 1,
      padding: "1rem 0",
      paddingLeft: "0.8rem",
      width: "calc(100% - 1.5rem)",
      borderRadius: "0.5rem",
      marginBottom: "0.75rem",
      alignItems: "center",
    },
    middleBar: {
      margin: "0px 10px",
    },
    triangleRight: {
      width: "0px",
      height: "0px",
      borderTop: "10px solid transparent",
      borderLeft: `20px solid ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
      borderBottom: "10px solid transparent",
    },
    smallTriangleRight: {
      width: "0px",
      height: "0px",
      borderTop: "7px solid transparent",
      borderLeft: `14px solid ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
      borderBottom: "7px solid transparent",
    },
    buttonStyle: {
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "25px",
      margin: "15px auto 10px ",
      fontWeight: 700,
      backgroundColor:
        localStorage.getItem("secondaryColor") || theme.palette.secondary.main,
      display: "block",
      textTransform: "inherit",
      textAlign: "center",
      "&:hover": {
        backgroundColor:
          localStorage.getItem("secondaryColor") ||
          theme.palette.secondary.main,
      },
    },
  };
};

export default withStyles((theme) =>
  createStyles({
    rootChildContainerpdf: {
      [theme.breakpoints.up("md")]: {
        padding: "1.5rem 2rem 2rem 2rem",
      },
      [theme.breakpoints.down("md")]: {
        padding: "1rem",
      },
    },
    ...roadmapStyles(theme),
  })
)(RoadMapUI);
