//@ts-nocheck
import React from "react";
import PastRoadmapsController, { Props } from "./PastRoadmapsController.web";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";

import moment from "moment";

import PastRoadmapDeleteDialog from "./PastRoadmapDeleteDialog.web";
import { edit, deleteIcon } from "../assets";

const EditIconForTable = ({ onClick }) => {
  return (
    <Icon style={{ cursor: "pointer" }} onClick={onClick}>
      <img
        src={edit}
        style={{
          height: "25px",
        }}
      />
    </Icon>
  );
};

export const DeleteIconForTable = ({ onClick }) => {
  return (
    <Icon style={{ cursor: "pointer" }} onClick={onClick}>
      <img
        src={deleteIcon}
        style={{
          height: "22px",
        }}
      />
    </Icon>
  );
};

export class PastRoadmaps extends PastRoadmapsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getAllPastRoadMap();
        }
      );
    }
  }

  openDeleteDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          row: data,
        },
        width: "40%",
        renderedComponent: PastRoadmapDeleteDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data.message === "Yes") {
          this.deleteRoadMap(data.roadmapId);
        }
      });
  };

  editRoadMap = (row) => {
    this.props.history.push({
      pathname: `/home/roadmaps/pastRoadmaps/${row.id}`,
      search: "?editingRoadmap=true",
      state: {
        roadmap: row,
        editingRoadmap: true,
      },
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.root}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={12} style={{ marginBottom: "2.5rem" }}>
              <Typography variant="h1" className={classes.title}>
                Access Past Roadmaps
              </Typography>
              <Typography className={classes.subTitle}>
                Here is a list of your saved roadmaps. You can view or edit them
                by clicking on the edit icon below.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead} width="45%">
                        Roadmap Name
                      </TableCell>
                      <TableCell className={classes.tableHead} align="center">
                        Date Created
                      </TableCell>
                      <TableCell className={classes.tableHead} align="center">
                        Date Last Modified
                      </TableCell>
                      <TableCell className={classes.tableHead} align="center">
                        Modify
                      </TableCell>
                      <TableCell className={classes.tableHead} align="center">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.roadMapList.length > 0 ? (
                      this.state.roadMapList.map((row) => (
                        <TableRow className={"pastRoadmap"} key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            width="45%"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {row.attributes.name}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.attributes.created_at).format(
                              "MM-DD-YYYY"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.attributes.updated_at).format(
                              "MM-DD-YYYY"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <EditIconForTable
                              className={"editIcon"}
                              onClick={this.editRoadMap.bind(this, row)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <DeleteIconForTable
                              className={"deleteIcon"}
                              onClick={this.openDeleteDialog.bind(this, row)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          {" "}
                          No past Roadmaps to show.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

const PastRoadmapsWithRouter = withRouter(PastRoadmaps);
const PastRoadmapsAlertBox = withAlertBox(PastRoadmapsWithRouter);
const PastRoadmapsLoader = withLoader(PastRoadmapsAlertBox);
const PastRoadmapsToast = withToast(PastRoadmapsLoader);
const PastRoadmapsWithDialog = withDialog(PastRoadmapsToast);

export default withStyles((theme) =>
  createStyles({
    
    
    
    title: {
      fontFamily: "Lato",
      fontSize: "1.75rem",
      color: "#54A4A6",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      fontFamily: "Lato",
      fontSize: "1rem",
      // fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: theme.palette.text.secondary,
    },
    tableHead: {
      fontFamily: "Lato",
      color: "#54A4A6",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "1rem",
    },
    
    
    
  })
)(withConfirmBox(PastRoadmapsWithDialog));
