//@ts-nocheck
import React from "react";
import CustomPackageCreationContainerController, {
  Props,
} from "./CustomPackageCreationContainerController.web";
import { withRouter } from "react-router";
import { Prompt } from "react-router-dom";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";

import CustomPackageCreationLandingPage from "./CustomPackageCreationLandingPage.web";
import CustomPackageCreationSelectFeaturesPage from "./CustomPackageCreationSelectFeaturesPage.web";
import CustomPackageCreationPricing from "./CustomPackageCreationPricing.web";
import CustomPackageCreationSummary from "./CustomPackageCreationSummary.web";

export class CustomPackageCreationContainer extends CustomPackageCreationContainerController {
  constructor(props: Props) {
    super(props);
  }
  unloadCallback = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };
  componentWillUnmount(): Promise<void> {
    window.removeEventListener("beforeunload", this.unloadCallback);
  }

  async componentDidMount() {
    window.addEventListener("beforeunload", this.unloadCallback);
    const routeState = this.props.history?.location?.state;
    // @ts-ignore
    const mode = routeState?.mode;
    // @ts-ignore
    const packageType = routeState?.package_type;
    // @ts-ignore
    const edit_package_id = routeState?.id;
    const isFromRoadMap = routeState?.isFromRoadMap;
    const roadMap = routeState?.roadMap;
    const token = await StorageProvider.get("authToken");
    this.setState({
      token: token,
      mode,
      packageType,
      edit_package_id,
      defaultPackageTemplate: edit_package_id || "select",
      currentStep: mode && mode == "edit" ? 1 : 0,
      isFromRoadMap: isFromRoadMap,
      roadMap: roadMap,
    });
  }
  goToNextStep = () => {
    if (this.state.currentStep < 3) {
      this.setState({
        currentStep: this.state.currentStep + 1,
      });
    }
  };
  goToPreviousStep = () => {
    if (this.state.currentStep > 0) {
      this.setState({
        currentStep: this.state.currentStep - 1,
      });
    }
  };

  goToFirmPricing = () => {
    this.props.history.push("/home/settings/pricing/firmPricing");
  };
  goToFirmPricingCustomization = () => {
    this.props.history.push("/home/settings/pricing/firmFinancials");
  };
  goToStep0 = () => {
    this.setState({
      currentStep: 0,
    });
  };
  syncStep1AndStep2 = (features: any, selectedFeatureList: any[]) => {
    const selectedFeatureList_ids = selectedFeatureList.map((feat) => {
      return feat?.feature_id;
    });
    const selectedFeatures: any[] = [];
    let totalAvaialbleFeatures = 0;
    for (let feature of features) {
      feature?.attributes?.features?.data?.forEach((feat: any) => {
        if (selectedFeatureList_ids.indexOf(feat?.attributes.feature_id) > -1) {
          const ddd = selectedFeatureList.filter((aa: any) => {
            return aa.feature_id == feat.attributes.feature_id;
          });
          if (ddd.length > 0) {
            for (let dd of ddd) {
              selectedFeatures.push({
                ...feat,
                attributes: {
                  ...feat.attributes,
                  ...dd,
                  id: feat.id,
                },
              });
            }
          }
          
        }
      });
    }
    return selectedFeatures;
  };

  sortAbc = (a, b) => {
    let fa = a?.order_number,
      fb = b?.order_number;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };
  goToStep1 = (values) => {
    this.setState(
      {
        currentStep: 1,
      },
      () => {
        const custom_package_features = values.custom_package_features.sort(
          this.sortAbc
        ).map((a,index)=>{
          return {...a,order_number:index}
        });
        const selectedFeatures = this.syncStep1AndStep2(
          this.state.allFeatureListForPricingScreen,
          custom_package_features
        );
        this.setState({
          selectedFeatureList: selectedFeatures,
          name : values.name,
          contract_period : values.contract_period,
          description : values.description,
          package_detail_link : values.package_detail_link,
          stratagic_rate:values.stratagic_rate,
          unfront_lump_suump_amount : values.unfront_lump_suump_amount,
          unfront_payment_type : values.unfront_payment_type,
          unfront_percentage : values.unfront_percentage,
          value_margin:values.value_margin
        });
      }
    );
  };
  goToStep2 = () => {
    this.setState({
      currentStep: 2,
    });
  };

  setFeaturePrice = (values: any) => {
    let totalContractValue = this.getTotalContractValue(values);
    let totalContractValueAfterRemovingUnfrontAmount = this.removeUnfrontValue(
      values,
      totalContractValue
    );

    this.setState(
      {
        name: values.name,
        description: values.description,
        contract_period: values.contract_period,
        stratagic_rate: values.stratagic_rate,
        package_detail_link: values.package_detail_link,
        value_margin: values.value_margin,
        is_unfront_payment: values.is_unfront_payment,
        unfront_payment_type: values.unfront_payment_type,
        unfront_percentage: values.unfront_percentage,
        unfront_lump_suump_amount: values.unfront_lump_suump_amount,
        totalContractValue: totalContractValue,
        totalContractValueAfterRemovingUnfrontAmount: totalContractValueAfterRemovingUnfrontAmount,
        custom_package_features: values.custom_package_features,
      },
      () => {
        this.goToNextStep();
      }
    );
  };
  setDefaultPackageTemplate = (event: any) => {
    this.setState(
      {
        defaultPackageTemplate: event?.target.value,
        selectedFeatureList: [],
      },
      () => {
        const pkg = this.state.packageList.find((pkg: any) => {
          return pkg?.id == event?.target.value;
        });
        if (pkg) {
          this.setState({
            name: "custom " + pkg?.attributes?.name,
            description: pkg?.attributes?.description,
            selectedFeatureList: [],
          });
        }
      }
    );
  };

  deleteSelectedPackage = (feature: any) => {
    this.removeSelectedFeatures({
      ...feature,
      id: feature?.package_feature_id,
    });
  };

  renderCurrentStep = () => {
    if (this.state.currentStep == 0) {
      return (
        <CustomPackageCreationLandingPage
          goToPreviousStep={this.goToPreviousStep.bind(this)}
          goToNextStep={this.goToNextStep.bind(this)}
          packageList={this.state.packageList}
          getPackages={this.getPackages.bind(this)}
          handleCreatedPackageType={this.handleCreatedPackageType.bind(this)}
          isCreateFromDefaultTemplate={this.state.isCreateFromDefaultTemplate}
          defaultPackageTemplate={this.state.defaultPackageTemplate}
          setDefaultPackageTemplate={this.setDefaultPackageTemplate.bind(this)}
          goToFirmPricingCustomization={this.goToFirmPricingCustomization.bind(
            this
          )}
          goToFirmPricing={this.goToFirmPricing.bind(this)}
          goToStep0={this.goToStep0.bind(this)}
          goToStep1={this.goToStep1.bind(this)}
          goToStep2={this.goToStep2.bind(this)}
        />
      );
    } else if (this.state.currentStep == 1) {
      return (
        <CustomPackageCreationSelectFeaturesPage
          searchfeild={this.state.searchfeild}
          setSearchField={(value) => this.setState({ searchfeild: value })}
          moveToNextStep={this.moveToNextStep.bind(this)}
          goToPreviousStep={this.goToPreviousStep.bind(this)}
          goToNextStep={this.goToNextStep.bind(this)}
          getAllFeatures={this.getAllFeatures.bind(this)}
          getAllFeaturesofSelectedPackage={this.getAllFeaturesofSelectedPackage.bind(
            this
          )}
          searchFeature={this.searchFeature.bind(this)}
          selectedPackageDetail={this.state.selectedPackageDetail}
          setAllFeatureList={this.setAllFeatureList.bind(this)}
          setSelectedFeatureList={this.setSelectedFeatureList.bind(this)}
          previousQuestion={this.previousQuestion.bind(this)}
          nextQuestion={this.nextQuestion.bind(this)}
          handleQuestionAnswerChange={this.handleQuestionAnswerChange.bind(
            this
          )}
          handleFilterDelete={this.handleFilterDelete.bind(this)}
          defaultPackageTemplate={this.state.defaultPackageTemplate}
          filterQuestionList={this.state.filterQuestionList}
          currentQuestionIndex={this.state.currentQuestionIndex}
          selectedFeatureList={this.state.selectedFeatureList}
          allFeatureList={this.state.allFeatureList}
          chipData={this.state.chipData}
          removeSelectedFeatures={this.removeSelectedFeatures.bind(this)}
          setSelectedFeatureAndRemoveFromAllFilterList={this.setSelectedFeatureAndRemoveFromAllFilterList.bind(
            this
          )}
          removeFeatureFromSelectedFeatureListAndAddtoAllFilterList={this.removeFeatureFromSelectedFeatureListAndAddtoAllFilterList.bind(
            this
          )}
          totalOneTimeFess={this.state.totalOneTimeFess}
          totalRecurringFess={this.state.totalRecurringFess}
          goToFirmPricingCustomization={this.goToFirmPricingCustomization.bind(
            this
          )}
          goToFirmPricing={this.goToFirmPricing.bind(this)}
          goToStep0={this.goToStep0.bind(this)}
          goToStep1={this.goToStep1.bind(this)}
          goToStep2={this.goToStep2.bind(this)}
          totalAvailalbeFeatures={this.state.totalAvailalbeFeatures}
          selectedCategory={this.state.selectedCategory}
          handleCategoryChange={this.handleCategoryChange.bind(this)}
          selectedSearchField={this.state.selectedSearchField}
          cancelSearch={this.cancelSearch.bind(this)}
          name={this.state.name}
          handleNameChange={this.handleNameChange.bind(this)}
          isCustomPackageTemplate={
            this.state.mode == "edit" &&
            this.state.packageType == "custom_package_management"
          }
          getAllFeaturesofSelectedCustomPackage={this.getAllFeaturesofSelectedCustomPackage.bind(
            this
          )}
          mode={this.state.mode}
          packageType={this.state.packageType}
          handleChipWhenFilterApplied={this.handleChipWhenFilterApplied.bind(
            this
          )}
          onFeatureDragEnd={this.onFeatureDragEnd.bind(this)}
          addCustomFeatureInDb = {this.addCustomFeatureInDb.bind(this)}
        />
      );
    } else if (this.state.currentStep == 2) {
      return (
        <CustomPackageCreationPricing
          handleNumberChange={this.handleNumberChange.bind(this)}
          handlePercent={this.handlePercent.bind(this)}
          handleUpFrontValue={this.handleUpFrontValue.bind(this)}
          deleteFeature={this.deleteFeature.bind(this)}
          handleStratagicRateChange={this.handleStratagicRateChange.bind(this)}
          onFeatureDragEndForPricing={this.onFeatureDragEndForPricing.bind(
            this
          )}
          handleNumberChangeForFeatures={this.handleNumberChangeForFeatures.bind(
            this
          )}
          handleNumberChangeForFeatureBump={this.handleNumberChangeForFeatureBump.bind(
            this
          )}
          getCurrentIndex={this.getCurrentIndex.bind(this)}
          getFeaturesForAutoComplete={this.getFeaturesForAutoComplete.bind(
            this
          )}
          sortFeature={this.sortFeature.bind(this)}
          handleNewFeature={this.handleNewFeature.bind(this)}
          getOneTimeFess={this.getOneTimeFess.bind(this)}
          getRecurringFess={this.getRecurringFess.bind(this)}
          getBumpPrice={this.getBumpPrice.bind(this)}
          goToPreviousStep={this.goToPreviousStep.bind(this)}
          goToNextStep={this.goToNextStep.bind(this)}
          name={this.state.name}
          description={this.state.description}
          package_detail_link={this.state.package_detail_link}
          stratagic_rate={this.state.stratagic_rate}
          value_margin={this.state.value_margin}
          contract_period={this.state.contract_period}
          selectedFeatureList={this.state.selectedFeatureList}
          bumpList={this.state.bumpList}
          getBumps={this.getBumps.bind(this)}
          setFeaturePrice={this.setFeaturePrice.bind(this)}
          custom_package_features={this.state.custom_package_features}
          goToFirmPricingCustomization={this.goToFirmPricingCustomization.bind(
            this
          )}
          goToFirmPricing={this.goToFirmPricing.bind(this)}
          goToStep0={this.goToStep0.bind(this)}
          goToStep1={this.goToStep1.bind(this)}
          goToStep2={this.goToStep2.bind(this)}
          allFeatureList={this.state.allFeatureList}
          getAllFeaturesofSelectedCustomPackage={this.getAllFeaturesofSelectedCustomPackage.bind(
            this
          )}
          mode={this.state.mode}
          packageType={this.state.packageType}
          is_unfront_payment={this.state.is_unfront_payment}
          unfront_payment_type={this.state.unfront_payment_type}
          unfront_percentage={this.state.unfront_percentage}
          unfront_lump_suump_amount={this.state.unfront_lump_suump_amount}
          deleteSelectedPackage={this.deleteSelectedPackage.bind(this)}
          allFeatureListForPricingScreen={
            this.state.allFeatureListForPricingScreen
          }
          getAllFeaturesForPriceingScreen={this.getAllFeaturesForPriceingScreen.bind(
            this
          )}
          default_firm_finance_strategic_price={
            this.state.default_firm_finance_strategic_price
          }
          setSelectedFeatureAndRemoveFromAllFilterList={this.setSelectedFeatureAndRemoveFromAllFilterList.bind(
            this
          )}
        />
      );
    } else if (this.state.currentStep == 3) {
      return (
        <CustomPackageCreationSummary
          goToPreviousStep={this.goToPreviousStep.bind(this)}
          goToNextStep={this.goToNextStep.bind(this)}
          name={this.state.name}
          packageType={this.state.packageType}
          description={this.state.description}
          contract_period={this.state.contract_period}
          stratagic_rate={this.state.stratagic_rate}
          package_detail_link={this.state.package_detail_link}
          value_margin={this.state.value_margin}
          is_unfront_payment={this.state.is_unfront_payment}
          unfront_payment_type={this.state.unfront_payment_type}
          unfront_percentage={this.state.unfront_percentage}
          unfront_lump_suump_amount={this.state.unfront_lump_suump_amount}
          totalContractValue={this.state.totalContractValue}
          totalContractValueAfterRemovingUnfrontAmount={
            this.state.totalContractValueAfterRemovingUnfrontAmount
          }
          custom_package_features={this.state.custom_package_features}
          createCustomPackage={this.createCustomPackageInDb.bind(this)}
          goToFirmPricingCustomization={this.goToFirmPricingCustomization.bind(
            this
          )}
          goToFirmPricing={this.goToFirmPricing.bind(this)}
          goToStep0={this.goToStep0.bind(this)}
          goToStep1={this.goToStep1.bind(this)}
          goToStep2={this.goToStep2.bind(this)}
        />
      );
    }
  };

  render() {
    return this.state.token ? (
      <>
        <Prompt
          when={!this.state.createCustomPackageId}
          message="Changes that you made may not be saved"
        />
        {this.renderCurrentStep()}
      </>
    ) : null;
  }
}
const CustomPackageCreationContainerWithRouter = withRouter(
  CustomPackageCreationContainer
);
const CustomPackageCreationContainerWithToast = withToast(
  CustomPackageCreationContainerWithRouter
);
const CustomPackageCreationContainerWithLoader = withLoader(
  CustomPackageCreationContainerWithToast
);
const CustomPackageCreationContainerWithAlertBox = withAlertBox(
  CustomPackageCreationContainerWithLoader
);
const CustomPackageCreationContainerWithDialogBox = withDialog(
  CustomPackageCreationContainerWithAlertBox
);

export default CustomPackageCreationContainerWithDialogBox;
