//@ts-nocheck
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { formatCurrency } from "./CustomPackage/CustomPackageCreationSelectFeaturesPage.web";
import { intitalQuestion } from "../../../components/src/PackageList.web";
export const configJSON = require("./config");

export default class CustomPackageInRoadMap<
  Props,
  S,
  SS
> extends BlockComponent<Props, S, SS> {
  getBumpsApiCallId: string = "";
  getAllFeaturesofSelectedPackageCallId: string = "";
  getAllFeaturesCallId: string = "";
  createCustomPackageCallId: string = "";
  getAllFeaturesForPriceingScreenCallId: string = "";

  nf: any = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    roundingMode: "ceil",
  });

  sortPackage = (a, b) => {
    if (
      new Date(a?.attributes.updated_at).getTime() <
      new Date(b?.attributes.updated_at).getTime()
    ) {
      return -1;
    }
    if (
      new Date(a?.attributes.updated_at).getTime() >
      new Date(b?.attributes.updated_at).getTime()
    ) {
      return 1;
    }
    return 0;
  };
  handleApiError = (responseJson: any) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson?.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleAllFeatureListForPricing = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data?.length) {
      this.setState({
        allFeatureListForPricingScreen: responseJson?.data,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };

  sortOrderNumber = (arr) => {
    return arr.sort((a, b) => {
      let fa = a?.attributes.order_number,
        fb = b?.attributes.order_number;

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  };
  getSelectedFeature = (features = {}) => {
    return this.sortOrderNumber(
      (features.selectedFeatures?.length !=
        this.state.selectedFeatureList.length
        ? this.state.selectedFeatureList
        : features.selectedFeatures
      ).map((item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            unique_feature_id:
              item.attributes.feature_id +
              "_" +
              item.attributes.id +
              "_" +
              index,
            order_number:
              item.attributes.order_number === 0
                ? 0
                : item.attributes.order_number || index,
            new_id: item.attributes.new_id || undefined,
          },
        };
      })
    );
  };

  handleAllFeatureList = (responseJson: CategoryRoot) => {
    this.props.hideLoader();
    if (responseJson?.data?.length) {
      this.setState((prevState) => {
        const prevFeatures = prevState.allFeatureList;
        const newFeatures = responseJson.data;
        const mergedFeatures = newFeatures?.map((newFeature) => {
          const oldFeature = prevFeatures.find((f) => f.id === newFeature.id);
          return {
            ...newFeature,
            attributes: {
              ...newFeature?.attributes,
              features: {
                ...newFeature?.attributes.features,
                data: newFeature?.attributes.features?.data
                  ? newFeature.attributes.features.data.map((newFeat) => {
                    const oldFeat = oldFeature?.attributes?.features?.data?.find(
                      (f) => f.id === newFeat.id
                    );
                    return {
                      ...newFeat,
                      attributes: {
                        ...newFeat.attributes,
                        new_id: oldFeat?.attributes?.new_id ?? undefined,
                      },
                    };
                  })
                  : [],
              },
            },
          };
        });
        const selectedFeatureList = [...prevState.selectedFeatureList];
        const features = this.processAllFeatureData(newFeatures, selectedFeatureList);
        return {
          allFeatureList: this.state.searchfeild ? mergedFeatures : features?.features,
          selectedFeatureList: this.state.searchfeild ? this.getSelectedFeature({ selectedFeatures: prevState.selectedFeatureList }) : this.getSelectedFeature(features),
          totalAvailalbeFeatures: features?.totalAvaialbleFeatures,
        };
      }, this.getTotalOneTimeAndRecurringFeesForFeatureSelectionScreen);
    } else {
      this.handleApiError(responseJson);
    }
  };
  total = (feature: any, totalRecurringFess: any, totalOneTimeFess: any) => {
    if (!feature?.attributes?._destroy) {
      if (feature?.attributes?.payment_period == "Recurring") {
        if (feature?.attributes?.payment_frequency == "Monthly") {
          totalRecurringFess += feature?.attributes.default_cost * 12;
        } else if (feature?.attributes?.payment_frequency == "Quarterly") {
          totalRecurringFess += feature?.attributes.default_cost * 4;
        } else if (
          feature?.attributes?.payment_frequency == "Yearly" ||
          feature?.attributes?.payment_frequency == "Annually"
        ) {
          totalRecurringFess += feature?.attributes.default_cost * 1;
        } else {
          totalRecurringFess += feature?.attributes.default_cost * 12;
        }
      } else if (feature?.attributes?.payment_period == "One Time") {
        totalOneTimeFess += feature?.attributes.default_cost;
      }
    }

    return { totalRecurringFess, totalOneTimeFess };
  };
  getTotalOneTimeAndRecurringFeesForFeatureSelectionScreen = () => {
    let totalOneTimeFess = 0;
    let totalRecurringFess = 0;
    if (this.state.selectedFeatureList?.length > 0) {
      for (const feature of this.state.selectedFeatureList) {
        totalOneTimeFess = this.total(
          feature,
          totalRecurringFess,
          totalOneTimeFess
        )?.totalOneTimeFess;
        totalRecurringFess = this.total(
          feature,
          totalRecurringFess,
          totalOneTimeFess
        )?.totalRecurringFess;
      }
      this.setState(
        {
          totalOneTimeFess,
          totalRecurringFess,
        },
        () => {
          const { totalAvaialbleFeatures } = this.processAllFeatureData(
            this.state.allFeatureList,
            this.state.selectedFeatureList.filter((a: any) => {
              return !a?.attributes?._destroy;
            })
          );
          this.setState({
            totalAvailalbeFeatures: totalAvaialbleFeatures,
          });
        }
      );
    } else {
      this.setState(
        {
          totalOneTimeFess,
          totalRecurringFess,
        },
        () => {
          const { totalAvaialbleFeatures } = this.processAllFeatureData(
            this.state.allFeatureList,
            this.state.selectedFeatureList.filter((a: any) => {
              return !a?.attributes?._destroy;
            })
          );
          this.setState({
            totalAvailalbeFeatures: totalAvaialbleFeatures,
          });
        }
      );
    }
  };
  processAllFeatureData = (features: any, selectedFeatureList: any[]) => {
    const selectedFeatureList_ids = selectedFeatureList.map((feat) => {
      return feat?.attributes.feature_id;
    });
    const selectedFeatures: any[] = [];
    let totalAvaialbleFeatures = 0;
    for (let feature of features) {
      let category_features = feature?.attributes?.features?.data;
      let aa =
        feature?.attributes?.features?.data?.forEach((feat: any) => {
          if (
            selectedFeatureList_ids.indexOf(feat?.attributes.feature_id) > -1
          ) {
            this.ccc(selectedFeatureList, feat, selectedFeatures);
          }
        }) || [];
      console.log(aa);
      if (feature?.attributes?.features?.data) {
        feature.attributes.features.data = category_features;
        totalAvaialbleFeatures += category_features.length;
      }
    }

    return { features, selectedFeatures, totalAvaialbleFeatures };
  };

  ccc = (selectedFeatureList, feat, selectedFeatures) => {
    const ddd = selectedFeatureList.filter((aa: any) => {
      return aa.attributes.feature_id == feat.attributes.feature_id;
    });
    if (ddd.length > 0) {
      for (let dd of ddd) {
        selectedFeatures.push({
          ...feat,
          attributes: {
            ...feat.attributes,
            ...dd.attributes,
            id: feat.id,
            new_id: feat.new_id || dd.new_id || dd.id,
          },
        });
      }
    }
  };

  handleSelectedPackageFeatureList = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setState({
        default_firm_finance_strategic_price:
          responseJson?.data?.attributes?.default_firm_finance_strategic_price,
        default_strategic_price:
          responseJson?.data?.attributes?.default_strategic_price,
      });
    }
    if (responseJson.data?.attributes?.features?.data?.length) {
      let selectedData = responseJson.data.attributes.features.data || [];
      selectedData = selectedData.map((item: any, index: any) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            unique_feature_id:
              item.attributes.feature_id +
              "_" +
              item.attributes.id +
              "_" +
              index,
          },
        };
      });
      this.setState(
        {
          selectedFeatureList: selectedData,
          name: responseJson.data?.attributes?.name || "",
          description: responseJson.data?.attributes?.description || "",
          package_detail_link:
            responseJson.data?.attributes?.package_detail_link || "",
          stratagic_rate: responseJson.data?.attributes?.stratagic_rate || "",
          value_margin: responseJson.data?.attributes?.value_margin || "",
          contract_period: responseJson.data?.attributes?.contract_period || "",
          is_unfront_payment:
            responseJson.data?.attributes?.payment_term?.is_upfront || false,
          unfront_payment_type:
            responseJson.data?.attributes?.payment_term?.upfront_percentage > 0
              ? "percentage"
              : "lumpsump",
          unfront_percentage:
            responseJson.data?.attributes?.payment_term?.upfront_percentage ||
            0,
          unfront_lump_suump_amount:
            responseJson.data?.attributes?.payment_term?.upfront_amount || 0,
          is_monthly_payment_term:
            responseJson.data?.attributes?.payment_term?.is_monthly_selected,
          is_quarterly_payment_term:
            responseJson.data?.attributes?.payment_term?.is_quarterly_selected,
          is_yearly_payment_term:
            responseJson.data?.attributes?.payment_term?.is_yearly_selected,
        },
        () => {
          this.getAllFeatures();
        }
      );
    } else {
      this.getAllFeatures();
      this.handleApiError(responseJson);
    }
  };
  handleGetBumpResponse = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        bumpList: responseJson?.data.sort((a, b) => {
          let fa = a?.attributes?.bump_name.toLowerCase(),
            fb = b?.attributes?.bump_name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }),
      });
    } else {
      this.handleApiError(responseJson);
    }
  };

  getDependentObjetive = (filter: any, businessType: any, objecttive: any) => {
    if (filter.key == "isForNewBusiness") {
      if (businessType == "Business") {
        objecttive.push("New Business - Business");
      } else if (businessType == "Individual") {
        objecttive.push("New Business - Individual");
      }
    }
    if (filter.key == "isForIRSIssues") {
      if (businessType == "Business") {
        objecttive.push("IRS Issues - Business");
      } else if (businessType == "Individual") {
        objecttive.push("IRS Issues - Individual");
      }
    }
    if (filter.key == "isForStrategicAdvisory") {
      if (businessType == "Business") {
        objecttive.push("Strategic Help - Business");
      } else if (businessType == "Individual") {
        objecttive.push("Strategic Help - Individual");
      }
    }
    return objecttive;
  };
  getObjective = () => {
    let businessType: string = "Individual";
    this.state.chipData.forEach((filter) => {
      if (filter.key == "isOwnsABusiness") {
        businessType = filter.label;
      }
    });
    let objecttive: string[] = [];
    this.state.chipData.forEach((filter) => {
      if (filter.key == "isWithAccounting") {
        objecttive.push("Accounting");
      }
      if (filter.key == "isWithPayroll") {
        objecttive.push("Payroll");
      }
      objecttive = this.getDependentObjetive(filter, businessType, objecttive);
    });
    return objecttive;
  };

  resetQuestionFilter = () => {
    return intitalQuestion;
  };
  calculateMonthlyPaymentTerm = (payment_term, paymentTerms) => {
    if (payment_term?.is_monthly_selected) {
      if (payment_term?.is_upfront) {
        if (payment_term.upfront_percentage > 0) {
          const totalUnFrontAmount =
            payment_term?.total_amount *
            (payment_term.upfront_percentage / 100);
          paymentTerms.push({
            paymentTermKey: "is_monthly_selected",
            value: `$ ${formatCurrency.format(
              Math.round(totalUnFrontAmount)
            )} Upfront+ $ ${formatCurrency.format(
              Math.round((payment_term?.total_amount - totalUnFrontAmount) / 12)
            )} / month`,
            simpleValue: "(monthly)",
            order_number: payment_term?.monthly_position,
          });
        } else if (payment_term?.upfront_amount > 0) {
          paymentTerms.push({
            paymentTermKey: "is_monthly_selected",
            value: `$ ${formatCurrency.format(
              Math.round(payment_term?.upfront_amount)
            )} Upfront+ $ ${formatCurrency.format(
              Math.round(
                (payment_term?.total_amount - payment_term?.upfront_amount) / 12
              )
            )} / month`,
            simpleValue: "(monthly)",
            order_number: payment_term?.monthly_position,
          });
        }
      } else {
        paymentTerms.push({
          paymentTermKey: "is_monthly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(payment_term?.total_amount / 12)
          )} / month`,
          simpleValue: "(monthly)",
          order_number: payment_term?.monthly_position,
        });
      }
    }
  };
  calculateQuatorlyPaymentTerm = (payment_term, paymentTerms) => {
    if (payment_term?.is_quarterly_selected) {
      if (payment_term?.is_upfront) {
        if (payment_term.upfront_percentage > 0) {
          const totalUnFrontAmount =
            payment_term?.total_amount *
            (payment_term.upfront_percentage / 100);
          paymentTerms.push({
            paymentTermKey: "is_quarterly_selected",
            value: `$ ${formatCurrency.format(
              Math.round(totalUnFrontAmount)
            )} Upfront+ $ ${formatCurrency.format(
              Math.round((payment_term?.total_amount - totalUnFrontAmount) / 4)
            )} / quarter`,
            simpleValue: "(quarterly)",
            order_number: payment_term?.quarterly_position,
          });
        } else if (payment_term?.upfront_amount > 0) {
          paymentTerms.push({
            paymentTermKey: "is_quarterly_selected",
            value: `$ ${formatCurrency.format(
              Math.round(payment_term?.upfront_amount)
            )} Upfront+ $ ${formatCurrency.format(
              Math.round(
                (payment_term?.total_amount - payment_term?.upfront_amount) / 4
              )
            )} / quarter`,
            simpleValue: "(quarterly)",
            order_number: payment_term?.quarterly_position,
          });
        }
      } else {
        paymentTerms.push({
          paymentTermKey: "is_quarterly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(payment_term?.total_amount / 4)
          )} / quarter`,
          simpleValue: "(quarterly)",
          order_number: payment_term?.quarterly_position,
        });
      }
    }
  };
  processPaymentTerms = (values: any) => {
    const paymentTerms = [];
    const payment_term = values?.data?.attributes?.payment_term;
    this.calculateMonthlyPaymentTerm(payment_term, paymentTerms);
    this.calculateQuatorlyPaymentTerm(payment_term, paymentTerms);
    if (payment_term?.is_yearly_selected) {
      if (payment_term?.is_upfront) {
        if (payment_term.upfront_percentage > 0) {
          const totalUnFrontAmount =
            payment_term?.total_amount *
            (payment_term.upfront_percentage / 100);
          paymentTerms.push({
            paymentTermKey: "is_yearly_selected",
            value: `$ ${formatCurrency.format(
              Math.round(totalUnFrontAmount)
            )} Upfront+ $ ${formatCurrency.format(
              Math.round(payment_term?.total_amount - totalUnFrontAmount)
            )} / yearly`,
            simpleValue: "(yearly)",
            order_number: payment_term?.yearly_position,
          });
        } else if (payment_term?.upfront_amount > 0) {
          paymentTerms.push({
            paymentTermKey: "is_yearly_selected",
            value: `$ ${formatCurrency.format(
              Math.round(payment_term?.upfront_amount)
            )} Upfront+ ${formatCurrency.format(
              Math.round(
                payment_term?.total_amount - payment_term?.upfront_amount
              )
            )} / yearly`,
            simpleValue: "(yearly)",
            order_number: payment_term?.yearly_position,
          });
        }
      } else {
        paymentTerms.push({
          paymentTermKey: "is_yearly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(payment_term?.total_amount)
          )} / yearly`,
          simpleValue: "(yearly)",
          order_number: payment_term?.yearly_position,
        });
      }
    }
    return paymentTerms;
  };
  getData = (values) => {
    let totalPackageWithPackageTerms = [...this.state.selectedPaymentTerms];
    let paymentTerms = [...this.state.selectedPaymentTerms];
    const paymentTerms1 = this.processPaymentTerms(values);
    paymentTerms = [...paymentTerms, ...paymentTerms1];
    const pkg = values?.data;
    if (paymentTerms.length <= 3) {
      paymentTerms.forEach((paymentterm: any) => {
        if (!paymentterm?.id) {
          totalPackageWithPackageTerms.push({
            ...pkg,
            attributes: {
              ...pkg.attributes,
              selectedPaymentTerm: {
                ...paymentterm,
                id: pkg?.id,
              },
            },
          });
        }
      });

      this.setState(
        {
          selectedPaymentTerms: totalPackageWithPackageTerms,
          activeStep: 2,
          selectedPackageList: [...this.state.selectedPackageList, pkg],
        },
        () => {
          this.resetPakcageCustomizationStep();
        }
      );
    } else {
      this.props.showToast({
        type: "error",
        message: "you can only choose maximum upto 3 payment terms",
      });
    }
  };

  resetPakcageCustomizationStep = () => {
    this.setState({
      selectedFeatureList: [],
      selectedCurrentPackage: null,
      chipData: [],
      filterQuestionList: this.resetQuestionFilter(),
      currentQuestionIndex: 0,
      isCreateCustomPackage: false,
      selectedPackageDetail: null,
      defaultPackageTemplate: "select",
      name: "",
      description: "",
      package_detail_link: "",
      stratagic_rate: "",
      value_margin: "",
      contract_period: "",
      totalOneTimeFess: 0,
      totalRecurringFess: 0,
      is_unfront_payment: false,
      unfront_payment_type: "percentage",
      unfront_percentage: 0,
      unfront_lump_suump_amount: 0,
      totalContractValue: 0,
      totalContractValueAfterRemovingUnfrontAmount: 0,
      custom_package_features: [],
      totalAvailalbeFeatures: 0,
      selectedCategory: [],
      selectedSearchField: "",
      is_monthly_payment_term: false,
      is_quarterly_payment_term: false,
      is_yearly_payment_term: false,
    });
  };
  calculatePrevioursPaymentTerm = (values) => {
    let prvioursPaymentTermLength = this.state.selectedPaymentTerms?.length;
    if (this.state.isEditRoadMapPackageTemplate) {
      const currentPaymentTerm = this.state.selectedPaymentTerms.find(
        (paymentTerm: any) => {
          return paymentTerm.id == this.state.defaultPackageTemplate;
        }
      );
      if (currentPaymentTerm?.attributes?.payment_term) {
        const pyterm = currentPaymentTerm?.attributes?.payment_term;
        if (pyterm?.is_monthly_selected) {
          prvioursPaymentTermLength--;
        }
        if (pyterm?.is_quarterly_selected) {
          prvioursPaymentTermLength--;
        }
        if (pyterm?.is_yearly_selected) {
          prvioursPaymentTermLength--;
        }
      }
    }
    if (values?.is_yearly_payment_term) {
      prvioursPaymentTermLength++;
    }
    if (values?.is_monthly_payment_term) {
      prvioursPaymentTermLength++;
    }
    if (values?.is_quarterly_payment_term) {
      prvioursPaymentTermLength++;
    }
    return prvioursPaymentTermLength;
  };
  getSavedForAllClientsValue = (value,condition) => {
    return condition ? { saved_for_all_clients: value } : {};
  };
  getDataOfCreateCustomPackage = (
    values,
    totalContractValueAfterRemovingUnfrontAmount,
    totalContractValue
  ) => {
    const parent_package_type =
      this.state.packageType == "custom_package_management"
        ? "CUSTOM"
        : "DEFAULT";
    const conditionalValues = {
      custom_package: {
        name: values.name,
        description: values.description,
        package_detail_link: values.package_detail_link,
        stratagic_rate: values.stratagic_rate,
        value_margin: values.value_margin,
        contract_period: values.contract_period,
        parent_package_type: this.state.isEditRoadMapPackageTemplate
          ? undefined
          : parent_package_type,
        is_duplicated: values.is_duplicated,
        package_id: this.state.isEditRoadMapPackageTemplate
          ? undefined
          : this.state.defaultPackageTemplate,
        road_customization_id: this.state.isCreateCustomPackage
          ? undefined
          : this.state.roadMapInfo.id,
        custom_package_features_attributes: values.custom_package_features.map(
          (feature, index) => {
            return {
              feature_id: feature?.feature_id,
              tag: feature?.tag,
              feature_name: feature?.feature_name_new || feature?.feature_name,
              payment_period: feature?.payment_period,
              default_cost: feature?.default_cost,
              package_category_id: feature?.package_category_id,
              package_feature_id: feature?.package_feature_id,
              id: this.state.isEditRoadMapPackageTemplate
                ? feature?.new_id
                : undefined,
              strategic_rate: feature?.stratagic_rate,
              payment_frequency: feature?.payment_frequency || undefined,
              _destroy: feature?._destroy || undefined,
              hour: feature?.hour,
              order_number: feature?.order_number || index,
              custom_package_bumps_attributes: feature.custom_package_bumps_attributes.map(
                (bump: any) => {
                  const bumpNew = this.state.bumpList.find((ss) => {
                    return ss.id == bump?.bump_name;
                  });
                  return {
                    bump_type: bumpNew?.attributes?.bump_type,
                    bump_name: bumpNew?.attributes?.bump_name,
                    bump_multiplier: bump.bump_multiplier,
                    bump_amount: bump.bump_amount,
                    total_bump_cost: bump.bump_multiplier * bump.bump_amount,
                    pricing_bump_id: bumpNew?.attributes?.id,
                    feature_name: feature?.feature_name,
                    id: this.state.isEditRoadMapPackageTemplate
                      ? bump?.id
                      : undefined,
                  };
                }
              ),
            };
          }
        ),
        custom_payment_term_attributes: {
          is_upfront: values.is_unfront_payment,
          upfront_percentage:
            values.unfront_payment_type == "percentage"
              ? values.unfront_percentage
              : undefined,
          upfront_amount:
            values.unfront_payment_type == "lumpsump"
              ? values.unfront_lump_suump_amount
              : undefined,
          month: Math.round(totalContractValueAfterRemovingUnfrontAmount / 12),
          quarter: Math.round(totalContractValueAfterRemovingUnfrontAmount / 4),
          year: Math.round(totalContractValueAfterRemovingUnfrontAmount / 1),
          total_amount: Math.round(totalContractValue),
          is_yearly_selected: values.is_yearly_payment_term,
          is_monthly_selected: values.is_monthly_payment_term,
          is_quarterly_selected: values.is_quarterly_payment_term,
        },
        ...this.getSavedForAllClientsValue(values.saved_for_all_clients,values.is_duplicated),
      },
    };
    return conditionalValues;
  };

  createCustomPackageInRoadMap = (
    values: any,
    totalContractValue,
    totalContractValueAfterRemovingUnfrontAmount
  ) => {
    const prvioursPaymentTermLength = this.calculatePrevioursPaymentTerm(
      values
    );
    if (prvioursPaymentTermLength <= 3) {
      this.props.showLoader();
      const headers = {
        "content-type": "application/json",
        token: this.state.token,
      };

      const data = this.getDataOfCreateCustomPackage(
        values,
        totalContractValueAfterRemovingUnfrontAmount,
        totalContractValue
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createCustomPackageCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.isEditRoadMapPackageTemplate
          ? `bx_block_custom_packages/custom_package_creation/${this.state.defaultPackageTemplate}`
          : `bx_block_custom_packages/custom_package_creation`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.isEditRoadMapPackageTemplate ? "PUT" : "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.props.showToast({
        type: "error",
        message: "you can only choose maximum upto 3 payment terms",
      });
    }
  };

  getAllFeaturesForPriceingScreen = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    let url = "bx_block_custom_packages/categories";

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFeaturesForPriceingScreenCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPackages = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessageForGetpackages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForGetpackages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageForGetpackages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contentmanagement/package_managements?data=package_checklists`
    );

    requestMessageForGetpackages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getPackagesApiCallId = requestMessageForGetpackages.messageId;

    runEngine.sendMessage(
      requestMessageForGetpackages.id,
      requestMessageForGetpackages
    );
  };
  getAllFeaturesofSelectedCustomPackage = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFeaturesofSelectedPackageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_packages/custom_package_creation/${this.state.defaultPackageTemplate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllFeaturesofSelectedPackage = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFeaturesofSelectedPackageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_packages/default_package?id=${this.state.defaultPackageTemplate}&road_customization_id=${this.state.roadMapInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBumps = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBumpsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/bump_pricings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllFeatures = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    const objecttive = this.getObjective();
    let url = "bx_block_custom_packages/categories";
    if (objecttive.length > 0) {
      url +=
        (url.indexOf("?") > -1 ? "&" : "?") +
        "objectives=" +
        objecttive.join(",");
    }
    if (this.state.selectedCategory?.length > 0) {
      url +=
        (url.indexOf("?") > -1 ? "&" : "?") +
        "categories=" +
        this.state.selectedCategory.join(",");
    }
    if (this.state.selectedSearchField) {
      url +=
        (url.indexOf("?") > -1 ? "&" : "?") +
        "search=" +
        this.state.selectedSearchField;
        this.setState({ searchfeild: true })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFeaturesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setAllFeatureList = (allFeatureList: any[]) => {
    this.setState({
      allFeatureList: allFeatureList,
    });
  };
  setSelectedFeatureList = (selectedFeatureList: any[]) => {
    this.setState({
      selectedFeatureList: selectedFeatureList,
    });
  };
  previousQuestion = () => {
    if (this.state.currentQuestionIndex >= 1) {
      this.setState({
        currentQuestionIndex: this.state.currentQuestionIndex - 1,
      });
    }
  };
  nextQuestion = () => {
    if (this.state.currentQuestionIndex < 5) {
      this.setState({
        currentQuestionIndex: this.state.currentQuestionIndex + 1,
      });
    }
  };
  handleQuestionAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterQuestionList = [...this.state.filterQuestionList];
    filterQuestionList[this.state.currentQuestionIndex] = {
      ...filterQuestionList[this.state.currentQuestionIndex],
      value: event.target.value,
    };
    const chipData = [...this.state.chipData];
    const chipIndex = chipData.findIndex((chip) => {
      return chip.key == event.target.name;
    });
    if (chipIndex > -1) {
      if (event.target.value) {
        chipData[chipIndex] = {
          label: event.target.value,
          key: event.target.name,
          isFromQuestionFilter: true,
        };
      } else {
        chipData.splice(chipIndex, 1);
      }
      this.setState(
        {
          chipData: chipData,
          filterQuestionList: filterQuestionList,
        },
        () => {
          this.getAllFeatures();
        }
      );
    } else {
      this.aaaa(event, filterQuestionList);
    }
  };
  aaaa = (event, filterQuestionList) => {
    if (event.target.value) {
      this.setState(
        {
          chipData: [
            ...this.state.chipData,
            {
              label: event.target.value,
              key: event.target.name,
              isFromQuestionFilter: true,
            },
          ],
          filterQuestionList: filterQuestionList,
        },
        () => {
          this.getAllFeatures();
        }
      );
    } else {
      this.setState(
        {
          filterQuestionList: filterQuestionList,
        },
        () => {
          this.getAllFeatures();
        }
      );
    }
  };
  handleFilterDelete = (chipToDelete: {
    key: string;
    label: string;
    isFromQuestionFilter?: boolean;
  }) => {
    this.setState(
      {
        chipData: this.state.chipData.filter(
          (chip) => chip.key !== chipToDelete.key
        ),
      },
      () => {
        let isApiCalled = false;
        if (chipToDelete?.isFromQuestionFilter) {
          isApiCalled = true;
          const filterQuestionList = [...this.state.filterQuestionList];
          const questionIndex = filterQuestionList.findIndex((question) => {
            return question?.name === chipToDelete.key;
          });
          filterQuestionList[questionIndex] = {
            ...filterQuestionList[questionIndex],
            value: null,
          };
          this.setState(
            {
              filterQuestionList: filterQuestionList,
            },
            () => {
              this.getAllFeatures();
            }
          );
        }
        const allCategory = [
          "Expert Historic Work",
          "Setup Help",
          "Done-For-You Management",
          "Tax Reduction Planning",
          "Strategic Planning",
        ];
        if (allCategory.indexOf(chipToDelete?.key) > -1) {
          isApiCalled = true;
          this.setState(
            {
              selectedCategory: this.state.selectedCategory.filter((cat) => {
                return cat != chipToDelete?.key;
              }),
            },
            () => {
              this.getAllFeatures();
            }
          );
        }
        if (!isApiCalled) {
          this.getAllFeatures();
        }
      }
    );
  };
  handleCategoryChange = (selectedCategory: any) => {
    this.setState(
      {
        selectedCategory: selectedCategory,
      },
      () => {
        this.getAllFeatures();
      }
    );
  };
  searchFeature = (event: any, newValue: any) => {
    this.setState(
      {
        selectedSearchField: newValue,
      },
      () => {
        this.getAllFeatures();
      }
    );
  };
  cancelSearch = () => {
    this.setState(
      {
        selectedSearchField: "",
      },
      () => {
        this.getAllFeatures();
      }
    );
  };
  handleNameChange = (name: string) => {
    this.setState({
      name: name,
    });
  };
  removeSelectedFeatures = (feature: any) => {
    // @ts-ignore
    console.log("aaaaaaaaa", feature);
    const allFeatures = [...this.state.allFeatureList];
    const featureIndex = allFeatures.findIndex((fet: any) => {
      return (
        fet.id ==
        (feature?.attributes?.package_category?.category_id ||
          feature?.package_category_id)
      );
    });
    if (featureIndex > -1) {
      const subCategoryId =
        feature?.attributes?.unique_feature_id || feature?.unique_feature_id;

      if (this.state.packageType == "custom_package_management") {
        const selectedFeatureList = [...this.state.selectedFeatureList];
        const selectdFeatureIndex = this.state.selectedFeatureList.findIndex(
          (subfet) => {
            return subfet.attributes.unique_feature_id == subCategoryId;
          }
        );
        this.state.selectedFeatureList.filter((subfet) => {
          return subfet.attributes.unique_feature_id !== subCategoryId;
        });
        console.log(
          "selectedFeatureListselectedFeatureList",
          selectedFeatureList
        );
        const selectedSubFeature = this.state.selectedFeatureList[
          selectdFeatureIndex
        ];
        selectedFeatureList[selectdFeatureIndex] = {
          ...selectedSubFeature,
          attributes: { ...selectedSubFeature.attributes, _destroy: 1 },
        };
        this.setState(
          {
            selectedFeatureList: selectedFeatureList.map(this.reMapFeature),
            custom_package_features: this.ggcpc(feature),
          },
          this.getTotalOneTimeAndRecurringFeesForFeatureSelectionScreen
        );
      } else {
        this.setState(
          {
            selectedFeatureList: this.state.selectedFeatureList
              .filter((subfet) => {
                return subfet.attributes.unique_feature_id != subCategoryId;
              })
              .map(this.reMapFeature),
            custom_package_features: this.ggcpc(feature),
          },
          this.getTotalOneTimeAndRecurringFeesForFeatureSelectionScreen
        );
      }
    }
  };
  reMapFeature = (item, index) => {
    return {
      ...item,
      attributes: {
        ...item.attributes,
        unique_feature_id:
          item.attributes.feature_id + "_" + item.attributes.id + "_" + index,
        order_number: index,
      },
    };
  };
  ggcpc = (feature) => {
    const unique_feature_id =
      feature?.attributes?.unique_feature_id || feature.unique_feature_id;
    return this.state.custom_package_features
      ?.filter((feat) => {
        return feat?.unique_feature_id != unique_feature_id;
      })
      .map((item, index) => {
        return {
          ...item,
          order_number: index,
          unique_feature_id: item.feature_id + "_" + item.id + "_" + index,
        };
      });
  };

  setSelectedFeatureAndRemoveFromAllFilterList = (
    allFeatures: any,
    selectedFeature: any,
    selectedSubFeature: any,
    subCategoryId: any,
    featureIndex: any
  ) => {
    this.setState(
      {
        selectedFeatureList: [
          ...this.state.selectedFeatureList,
          {
            ...selectedSubFeature,
            attributes: {
              ...selectedSubFeature.attributes,
            },
          },
        ].map((item, index) => {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              unique_feature_id:
                item.attributes.feature_id +
                "_" +
                item.attributes.id +
                "_" +
                index,
              order_number:
                item.attributes.order_number === 0
                  ? 0
                  : item.attributes.order_number || index,
            },
          };
        }),
      },
      () => {
        if (selectedFeature?.attributes?.features?.data) {
          this.getTotalOneTimeAndRecurringFeesForFeatureSelectionScreen();
        }
      }
    );
  };
  removeFeatureFromSelectedFeatureListAndAddtoAllFilterList = (
    subCategoryId: any,
    selectedFeature: any,
    allFeatures: any,
    featureIndex: number
  ) => {
    const subfeature = this.state.selectedFeatureList.find((subfet) => {
      return subfet.id == subCategoryId;
    });
    // const updateSubFeatures = selectedFeature.attributes.features.data
    //   ? [...selectedFeature.attributes.features.data, subfeature]
    //   : [];
    // selectedFeature.attributes.features.data = updateSubFeatures;
    // allFeatures[featureIndex] = selectedFeature;
    // this.setState(
    //   {
    //     allFeatureList: allFeatures,
    //   },
    //   () => {
    this.setState(
      {
        selectedFeatureList: this.state.selectedFeatureList
          .filter((subfet) => {
            return (
              subfet.attributes.unique_feature_id !=
              subfeature?.attributes.unique_feature_id
            );
          })
          .map((item, index) => {
            return {
              ...item,
              attributes: {
                ...item.attributes,
                unique_feature_id:
                  item.attributes.feature_id +
                  "_" +
                  item.attributes.id +
                  "_" +
                  index,
                order_number:
                  item.attributes.order_number === 0
                    ? 0
                    : item.attributes.order_number || index,
              },
            };
          }),
      },
      this.getTotalOneTimeAndRecurringFeesForFeatureSelectionScreen
    );
    //   }
    // );
  };
  goToPreviousStep = () => {
    if (this.state.currentPackageCustomizationIndex == 1) {
      this.setState({
        currentPackageCustomizationIndex: 0,
      });
    }
  };
  getTotalContractValue = (values: any) => {
    let totalContractValue = 0;

    values?.custom_package_features
      .filter((d) => {
        return !d._destroy;
      })
      .forEach((feature: any) => {
        let featuresPriceTotal = 0;
        const stratagic_rate =
          +feature?.stratagic_rate || +values?.stratagic_rate;
        const default_cost = +feature?.default_cost;
        const payment_period = feature?.payment_period;
        const payment_frequency = feature?.payment_frequency;
        const hour = +feature?.hour;
        if (payment_period == "One Time") {
          featuresPriceTotal += this.getOneTimeFess(
            hour,
            stratagic_rate,
            default_cost
          );
        } else if (payment_period == "Recurring") {
          featuresPriceTotal += this.getRecurringFess(
            payment_frequency,
            hour,
            stratagic_rate,
            default_cost,
            values?.contract_period
          );
        }
        featuresPriceTotal += this.getBumpPrice(feature);

        totalContractValue += +featuresPriceTotal;
      });
    if (values?.value_margin) {
      totalContractValue += +values?.value_margin;
    }
    return totalContractValue;
  };
  removeUnfrontValue = (values: any, totalContractValue: any) => {
    let totalContractValueAfterRemovingUnfrontAmount = +totalContractValue;
    if (values.is_unfront_payment) {
      if (values.unfront_payment_type == "percentage") {
        totalContractValueAfterRemovingUnfrontAmount =
          totalContractValue -
          totalContractValue * (+values.unfront_percentage / 100);
      } else if (values.unfront_payment_type == "lumpsump") {
        totalContractValueAfterRemovingUnfrontAmount =
          totalContractValue - +values.unfront_lump_suump_amount;
      }
    }
    return totalContractValueAfterRemovingUnfrontAmount;
  };
  getOneTimeFess = (hour: any, stratagic_rate: any, default_cost: any) => {
    let featuresPriceTotal = 0;
    if (hour) {
      featuresPriceTotal += hour * stratagic_rate;
    } else {
      featuresPriceTotal += default_cost;
    }
    return featuresPriceTotal;
  };
  getRecurringFess = (
    payment_frequency: any,
    hour: any,
    stratagic_rate: any,
    default_cost: any,
    contract_period: any
  ) => {
    let featuresPriceTotal = 0;
    if (payment_frequency == "Monthly") {
      if (hour) {
        featuresPriceTotal += hour * stratagic_rate * +contract_period;
      } else {
        featuresPriceTotal += default_cost * +contract_period;
      }
    } else if (payment_frequency == "Quarterly") {
      if (hour) {
        featuresPriceTotal += hour * stratagic_rate * (+contract_period / 3);
      } else {
        featuresPriceTotal += default_cost * (+contract_period / 3);
      }
    } else if (
      payment_frequency == "Yearly" ||
      payment_frequency == "Annually"
    ) {
      if (hour) {
        featuresPriceTotal += hour * stratagic_rate * (+contract_period / 12);
      } else {
        featuresPriceTotal += default_cost * (+contract_period / 12);
      }
    }
    return featuresPriceTotal;
  };
  getBumpPrice = (feature: any) => {
    let featuresPriceTotal = 0;
    feature.custom_package_bumps_attributes.forEach(
      (bump: any, bumpIndex: number) => {
        featuresPriceTotal +=
          (+feature?.custom_package_bumps_attributes[bumpIndex]
            .bump_multiplier || 1) *
          (+feature?.custom_package_bumps_attributes[bumpIndex].bump_amount ||
            0);
      }
    );
    return featuresPriceTotal;
  };

  handleChipWhenFilterApplied = (data) => {
    let chipData = [];
    let filterQuestionList = [];
    const aa = this.applyObjectiveFilter(data);
    console.log("dkdkdkdkd", aa);
    chipData = aa?.chipData || [];
    filterQuestionList = aa?.filterQuestionList || [];
    if (data?.categories?.length > 0) {
      for (const obj of data.categories) {
        const chipIndex = chipData.findIndex((chip) => {
          return chip.key == obj;
        });

        if (chipIndex < 0) {
          chipData.push({
            label: obj,
            key: obj,
            isFromQuestionFilter: false,
            type: "category",
          });
        }
      }
    }
    this.setState(
      {
        chipData: chipData,
        selectedCategory: data?.categories?.length ? data?.categories : [],
        filterQuestionList: filterQuestionList,
        searchfeild: true
      },
      () => {
        if (chipData.length == 0) {
          this.setState(
            {
              filterQuestionList: this.resetQuestionFilter(),
            },
            this.getAllFeatures()
          );
        } else {
          this.getAllFeatures();
        }
      }
    );
  };
  aa = (
    chipStateIndex,
    newfilterQuestionListIndex,
    filterQuestionList,
    obj
  ) => {
    if (chipStateIndex > -1) {
      newfilterQuestionListIndex.push(chipStateIndex);
      filterQuestionList = filterQuestionList.map((qs, index) => {
        if (newfilterQuestionListIndex.indexOf(index) > -1) {
          return { ...qs, value: qs?.value ? qs?.value : obj?.value };
        } else {
          return { ...qs, value: null };
        }
      });
    }
    return filterQuestionList;
  };
  applyObjectiveFilter = (data) => {
    const chipData = [];
    let filterQuestionList = [...this.state.filterQuestionList];
    let newfilterQuestionListIndex = [];
    if (data?.objectives?.length > 0) {
      for (const obj of data.objectives) {
        const chipIndex = chipData.findIndex((chip) => {
          return chip.key == obj.name;
        });
        const chipStateIndex = this.state.filterQuestionList.findIndex(
          (chip) => {
            return chip.name == obj.name;
          }
        );
        if (chipIndex < 0) {
          chipData.push({
            label: obj.value,
            key: obj.name,
            isFromQuestionFilter: true,
          });
        }
        filterQuestionList = this.aa(
          chipStateIndex,
          newfilterQuestionListIndex,
          filterQuestionList,
          obj
        );
      }
    }
    return { chipData, filterQuestionList };
  };
  handleCreatedPackageType = (type: boolean) => {
    this.setState(
      {
        isCreateFromDefaultTemplate: type,
      },
      () => {
        this.setState({
          name: "Default Package",
          selectedFeatureList: [],
          filterQuestionList: this.resetQuestionFilter(),
          currentQuestionIndex: 0,
          selectedPackageDetail: null,
          defaultPackageTemplate: "select",
          description: "",
          package_detail_link: "",
          stratagic_rate: "",
          value_margin: "",
          contract_period: "",
          totalOneTimeFess: 0,
          totalRecurringFess: 0,
          is_unfront_payment: false,
          unfront_payment_type: "percentage",
          unfront_percentage: 0,
          unfront_lump_suump_amount: 0,
          totalContractValue: 0,
          totalContractValueAfterRemovingUnfrontAmount: 0,
          custom_package_features: [],
          totalAvailalbeFeatures: 0,
          selectedCategory: [],
          selectedSearchField: "",
        });
      }
    );
  };
  setDefaultPackageTemplate = (event: any) => {
    this.setState(
      {
        defaultPackageTemplate: event?.target.value,
        selectedFeatureList: [],
      },
      () => {
        const pkg = this.state.packageListForDropDown.find((pkg: any) => {
          return pkg?.id == event?.target.value;
        });
        if (pkg) {
          this.setState({
            name: "custom " + pkg?.attributes?.name,
            description: pkg?.attributes?.description,
            selectedFeatureList: [],
          });
        }
      }
    );
  };
  onFeatureDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      if (destination.droppableId == "selectedfeaturesDroppable") {
        if (destination.index !== source.index) {
          const selectedFeatureList = this.moveFeatureByIndex(
            [...this.state.selectedFeatureList]?.filter((a) => {
              return !a?.attributes?._destroy;
            }),
            source,
            destination
          );
          const selectedFeatureListWithorder_number = selectedFeatureList.map(
            (item, index) => {
              return {
                ...item,
                attributes: {
                  ...item.attributes,
                  order_number: index,
                  unique_feature_id:
                    item.attributes.feature_id +
                    "_" +
                    item.attributes.id +
                    "_" +
                    index,
                },
              };
            }
          );
          this.setState({
            selectedFeatureList: selectedFeatureListWithorder_number,
          });
        }
      }
      return;
    }
    const dragable_id = result?.draggableId;
    const subCategoryId = dragable_id?.split("_")[0];
    const categoryId = dragable_id?.split("_")[1];
    const allFeatures = [...this.state.allFeatureList];
    const featureIndex = allFeatures.findIndex((fet: any) => {
      return fet.id == categoryId;
    });
    const selectedFeature = allFeatures[featureIndex];
    let subfeatureIndex = selectedFeature?.attributes?.features?.data?.findIndex(
      (fet: any) => {
        return fet.id == subCategoryId;
      }
    );
    let selectedSubFeature;
    if (subfeatureIndex > -1) {
      selectedSubFeature =
        selectedFeature?.attributes?.features?.data[subfeatureIndex];
    }
    if (
      source.droppableId == "allfeaturesDroppable" &&
      destination.droppableId == "selectedfeaturesDroppable"
    ) {
      this.setSelectedFeatureAndRemoveFromAllFilterList(
        allFeatures,
        selectedFeature,
        selectedSubFeature,
        subCategoryId,
        featureIndex
      );
    } else {
      this.removeFeatureFromSelectedFeatureListAndAddtoAllFilterList(
        subCategoryId,
        selectedFeature,
        allFeatures,
        featureIndex
      );
    }
  };
  handleNewFeature = (
    afeature,
    setFieldValue,
    handleChange,
    isFromRoadMap,
    arrayHelpers,
    event,
    value
  ) => {
    const custom_package_features =
      arrayHelpers?.form.values.custom_package_features || [];
    const aIndex = custom_package_features.findIndex((ff) => {
      return ff.unique_feature_id == afeature.unique_feature_id;
    });
    if (afeature.isNewFeature) {
      let feature = {};
      this.state.allFeatureListForPricingScreen.forEach((element) => {
        element.attributes.features.data.forEach((eleent2) => {
          if (
            eleent2?.attributes.feature_name == value.attributes.feature_name
          ) {
            feature = eleent2;
          }
        });
      });
      setFieldValue(
        `custom_package_features[${aIndex}].unique_feature_id`,
        feature.attributes.feature_id +
        "_" +
        feature.attributes.id +
        "_" +
        aIndex
      );
      setFieldValue(
        `custom_package_features[${aIndex}].feature_name`,
        feature.id
      );
      setFieldValue(
        `custom_package_features[${aIndex}].feature_name_new`,
        feature.attributes.feature_name
      );
      setFieldValue(
        `custom_package_features[${aIndex}].tag`,
        feature.attributes.tag
      );
      setFieldValue(
        `custom_package_features[${aIndex}].payment_period`,
        feature.attributes.payment_period
      );
      if (feature.attributes.payment_period == "Recurring") {
        setFieldValue(
          `custom_package_features[${aIndex}].payment_frequency`,
          feature.attributes.payment_frequency
        );
      }
      setFieldValue(
        `custom_package_features[${aIndex}].feature_id`,
        feature.attributes.feature_id
      );
      setFieldValue(
        `custom_package_features[${aIndex}].default_cost`,
        feature.attributes.default_cost
      );
      setFieldValue(
        `custom_package_features[${aIndex}].package_feature_id`,
        feature.attributes.id
      );
      setFieldValue(
        `custom_package_features[${aIndex}].package_category_id`,
        feature.attributes.package_category.category_id
      );

      setFieldValue(
        `custom_package_features[${aIndex}].custom_package_bumps_attributes`,
        []
      );
      if (!isFromRoadMap) {
        const subCategoryId = feature.attributes?.id;
        const categoryId = feature?.attributes?.package_category.category_id;
        const allFeatures = [...this.state.allFeatureList];
        const featureIndex = allFeatures.findIndex((fet: any) => {
          return fet.id == categoryId;
        });
        const selectedFeature = allFeatures[featureIndex];
        let subfeatureIndex = selectedFeature?.attributes?.features?.data?.findIndex(
          (fet: any) => {
            return fet.id == subCategoryId;
          }
        );
        let selectedSubFeature;
        if (subfeatureIndex > -1) {
          selectedSubFeature =
            selectedFeature?.attributes?.features?.data[subfeatureIndex];
        }

        this.setSelectedFeatureAndRemoveFromAllFilterList(
          allFeatures,
          selectedFeature,
          selectedSubFeature,
          subCategoryId,
          featureIndex
        );
      }
    } else {
      handleChange(event);
    }
  };
  getFeaturesForAutoComplete = (selectedFeature) => {
    const features = [];
    this.state.allFeatureListForPricingScreen?.map((feature1: any) => {
      feature1?.attributes?.features?.data.map((feature2) => {
        features.push(feature2);
      });
    });

    return features.sort((a, b) => {
      let fa = a?.attributes?.feature_name.toLowerCase(),
        fb = b?.attributes?.feature_name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  };
  removeDuplicateFeature = (features, selectedFeature) => {
    for (let i = features.length - 1; i >= 0; i--) {
      if (selectedFeature) {
        const dd = selectedFeature.filter((aa) => {
          return !aa._destroy;
        });
        for (const element of dd) {
          if (
            features[i] &&
            features[i].attributes?.feature_id === element.feature_id
          ) {
            features.splice(i, 1);
          }
        }
      }
    }
    return features;
  };
  moveToNextStep = () => {
    if (
      this.state.selectedFeatureList.filter((aa) => {
        return !aa.attributes._destroy;
      }).length > 0
    ) {
      this.goToNextStep();
    } else {
      this.props.showToast({
        type: "error",
        message: "Please select at least one feature",
      });
    }
  };

  handleNumberChangeForFeatures = (
    setFieldValue,
    feature,
    arrayHelpers,
    name,
    isHour,
    value
  ) => {
    const custom_package_features =
      arrayHelpers.form.values.custom_package_features;
    const aIndex = custom_package_features
      .map((e) => e.unique_feature_id)
      .lastIndexOf(feature.unique_feature_id);
    setFieldValue(`custom_package_features[${aIndex}].${name}]`, value.value);
    if (isHour) {
      if (!feature.stratagic_rate) {
        setFieldValue(
          `custom_package_features[${aIndex}].stratagic_rate`,
          arrayHelpers.form.values?.stratagic_rate
        );
      }
    }
    return aIndex;
  };
  handleNumberChangeForFeatureBump = (
    setFieldValue,
    feature,
    arrayHelpers,
    name,
    bumpIndex,
    value
  ) => {
    const custom_package_features =
      arrayHelpers.form.values.custom_package_features;
    const aIndex = custom_package_features
      .map((e) => e.unique_feature_id)
      .lastIndexOf(feature.unique_feature_id);

    setFieldValue(
      `custom_package_features[${aIndex}].custom_package_bumps_attributes[${bumpIndex}].${name}`,
      value.value
    );
  };

  getCurrentIndex = (arrayHelpers, feature) => {
    const custom_package_features =
      arrayHelpers.form.values.custom_package_features;
    const aIndex = custom_package_features
      .map((e) => e.unique_feature_id)
      .lastIndexOf(feature?.unique_feature_id);
    return aIndex;
  };
  handleNumberChange = (setFieldValue, values, event) => {
    setFieldValue(event?.event?.target.name, values.value);
  };
  handleStratagicRateChange = (values, setFieldValue, value, event) => {
    setFieldValue(event?.event?.target.name, value.value);
    if (values.custom_package_features.length > 0) {
      values.custom_package_features.forEach((feature, index) => {
        if (feature.hour) {
          setFieldValue(
            `custom_package_features[${index}].stratagic_rate`,
            value.value
          );
        }
      });
    }
  };
  handlePercent = (values) => {
    const { floatValue } = values;
    return floatValue <= 100;
  };
  handleUpFrontValue = (totalContractValue, value) => {
    const { floatValue } = value;
    return floatValue < totalContractValue;
  };
  deleteFeature = (
    arrayHelpers,
    featureIndex,
    feature,
    setFieldValue,
    isFromRoadMap
  ) => {
    if (this.state.packageType == "custom_package_management") {
      if (!feature.isNewFeature) {
        const custom_package_features =
          arrayHelpers.form.values.custom_package_features;
        const aIndex = custom_package_features
          .map((e) => e.unique_feature_id)
          .lastIndexOf(feature.unique_feature_id);

        setFieldValue(`custom_package_features[${aIndex}]._destroy`, 1);
      } else {
        const custom_package_features =
          arrayHelpers.form.values.custom_package_features;
        console.log("abcd", feature);
        if (feature.unique_feature_id) {
          const aIndex = custom_package_features
            .map((e) => e.unique_feature_id)
            .lastIndexOf(feature.unique_feature_id);
          arrayHelpers.remove(aIndex);
        } else {
          arrayHelpers.remove(custom_package_features.length - 1);
        }
      }
    } else {
      arrayHelpers.remove(featureIndex);
    }
    if (!feature.isNewFeature && !isFromRoadMap) {
      this.deleteSelectedPackage(feature);
    }
  };
  moveFeatureByIndex = (temp_custom_package_features, source, destination) => {
    const custom_package_features = [...temp_custom_package_features];
    let moveEle = source.index;
    let moveToIndx = destination.index;
    while (moveEle < 0) {
      moveEle += custom_package_features.length;
    }
    while (moveToIndx < 0) {
      moveToIndx = moveToIndx + custom_package_features.length;
    }
    if (moveToIndx >= custom_package_features.length) {
      let un = moveToIndx - custom_package_features.length + 1;
      while (un--) {
        custom_package_features.push(undefined);
      }
    }
    custom_package_features.splice(
      moveToIndx,
      0,
      custom_package_features.splice(moveEle, 1)[0]
    );
    return custom_package_features;
  };
  onFeatureDragEndForPricing = (values, setFieldValue, result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      if (destination.droppableId == "selectedfeaturesDroppable") {
        if (destination.index !== source.index) {
          const custom_package_features = this.moveFeatureByIndex(
            [...values.custom_package_features],
            source,
            destination
          );

          const custom_package_featuresWithorder_number = custom_package_features.map(
            (item, index) => {
              return {
                ...item,
                order_number: index,
                unique_feature_id:
                  item.feature_id + "_" + item.id + "_" + index,
              };
            }
          );
          setFieldValue(
            "custom_package_features",
            custom_package_featuresWithorder_number
          );
        }
      }
    }
  };
  sortFeature = (a, b) => {
    let fa = a?.attributes?.order_number,
      fb = b?.attributes?.order_number;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };
}
